<template>
    <div>
        <a-spin :spinning="loading">
            <Header title="线上会员卡充值记录" @back="onBack(false)"></Header>
			
			<div style="margin: 20px 0;">
				<a-row>
					<a-col :span="12">线上会员卡号：{{ record.cardNumber }}</a-col>
					<a-col :span="12" style="text-align: right;">累计充值金额： {{ record.total }}元</a-col>
				</a-row>
			</div>

            <a-table :dataSource="list" :columns="columns" :pagination="pagination">
				<template #bodyCell="{ column, record, index }">
					<template v-if="column.key === 'time'">
						{{ transDateTime(record.createTime) }}
					</template>
				</template>
			</a-table>

            <div style="margin: 20px 0;text-align: center;">
                <a-button @click="onBack(false)">返回</a-button>
            </div>
        </a-spin>
    </div>
</template>

<script>
    import {
		Icon
	} from '@/components/icon/icon.js';
	import Header from '@/components/header/header.vue';
	import { getRechargeRecord } from '@/service/modules/coupon';
    export default {
        components: { Icon, Header },
        props: {
            id: {
                type: Number,
                default: 0
            }
        },
        data(){
            return {
                loading: false,
				record: {},
                list: [],
                columns: [{
                    title: '充值时间',
					key: 'time'
                }, {
                    title: '充值前余额',
					dataIndex: 'beforeBalance'
                }, {
                    title: '充值后余额',
					dataIndex: 'afterBalance'
                }, {
                    title: '实付金额',
					dataIndex: 'rechargeAmount'
                }, {
					title: '到账金额',
					dataIndex: 'arrivalAmount'
				}],
                pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
            }
        },
		created() {
			this.getData();
		},
        methods: {
            onBack(isRef) {
                this.$emit('back', isRef);
            },
			async getData() {
				this.loading = true;
				try {
					let ret = await getRechargeRecord({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						userCardId: this.id
					});
					this.loading = false;
					if (ret.code === 200) {
						this.record = ret.data.list[0];
						this.list = ret.data.list.slice(1);
						this.pagination.total = ret.data.totalCount;
					}
				} catch(e) {
					this.loading = false;
				}
			}
        }
    }
</script>

<style scoped></style>